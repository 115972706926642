.content-block .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .range-box-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .date-range-label {
    margin-right: 8px; /* Adjust space between label and DateRangeBox */
    margin-top: 15px;
    font-size: 16px; /* Adjust size as necessary */
  }

  .red{
    color: lightcoral;
    font-weight: bold;
  }

  .green{
    color: green;
  }