@use "../../variables.scss" as *;
@use "sass:math";

.view-wrapper-calendar {
  position: relative;
  flex-direction: column;
  display: flex;
  height: inherit;
  padding: 0 var(--page-padding);

  .calendar-content {
    display: flex;
    height: inherit;
    gap: 16px;
    padding-top: var(--content-padding);
    padding-bottom: var(--theme-padding);

    .left-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: justify;
      gap: var(--theme-padding);

      .buttons {
        display: flex;
        width: 100%;
        gap: 16px;
        margin: 10px;
        padding: 0 var(--theme-padding);
        justify-content: center;

        .dx-button {
          width: calc((100% - var(--theme-padding)) / 2);
          font-size: 13px;
          border-radius: 4px;
        }
      }

      .calendar {
        align-self: center;
        padding: var(--theme-padding) 0;
      }

      .dx-calendar {
        width: 300px;
      }
    }

    .main-content {
      flex: 1;
      height: inherit;
      border: 1px grey;
      border-radius: 8px;
      overflow-x: auto;
    }
  }

  .dx-scheduler-appointment-content {
    color: #333;
  }

  .dx-scheduler-agenda .dx-scheduler-appointment-recurrence-icon {
    color: #333;
  }

  .dx-scheduler-header {
    border-radius: 8px 8px 0 0;
    height: var(--scheduler-toolbar-height);
  }

  .dx-scheduler-work-space {
    border-radius: 0 0 8px 8px;
  }
}

.dx-tooltip-wrapper>.dx-popup-normal {
  background-color: #fff !important;
  color: var(--base-text-color) !important;
  border-radius: 8px !important;
  max-width: 360px;
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.24));
}

.dx-scheduler-header .dx-toolbar .dx-toolbar-items-container {
  height: 56px !important;
}

.dx-scheduler-appointment-title {
  font-size: 12px;
  white-space: normal;
}

.custom-toast {
  color: hsl(0, 0%, 1%) !important;
  padding: '10px';
  margin: '10px'
}

.dx-toast-info {
  background-color: #009688 !important;
  padding: '10px';
}

.right-header .list-header {
  display: flex;
  vertical-align: middle;
  align-items: center;
  gap: 8px;
  color: var(--base-text-color);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 16px;
  justify-content: center;
}

.dx-layout-manager .dx-field-item:not(.dx-last-col) {
  padding-inline-end: 0px;
}

.dx-list-group-header {
  justify-content: center;
}

.dx-first-row .dx-first-col .dx-last-row .dx-field-item .dx-col-0 .dx-field-button-item {
  text-align: left;
}


.form-container {
  padding: 20px;
  background: #f7f8fa;
  border-radius: 8px;
  margin: 20px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.select-box {
  margin-bottom: 15px;
}

button {
  padding: 10px 20px;
  border-radius: 5px;
}
.buttons-column > .column-header {
  flex-grow: 0;
  width: 150px;
  height: 35px;
  font-size: 100%;
  opacity: 0.6;
  text-align: left;
  padding-left: 15px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dx-widget{
  font-size: 12px;
}