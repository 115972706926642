.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

// Profile Form
.profile-form {
  margin-top: 20px; 

  .dx-field {
    margin-bottom: 10px; 
  }

  .dx-field-label {
    font-weight: bold; // Makes labels bold
    color: #333; // Darker color for better readability
  }

  .dx-field-value {
    input {
      border: 1px solid #ccc; // Adds a border to input fields
      border-radius: 4px; // Rounded corners for inputs
      padding: 8px; // Adds padding inside input fields
      width: 100%; // Ensures inputs take full width
      box-sizing: border-box; // Ensures padding and border are included in the width
      font-size: 14px; // Sets a consistent font size
    }

    input[disabled] {
      background-color: #f5f5f5; // Light gray background for disabled inputs
      cursor: not-allowed; // Changes cursor to not-allowed for disabled inputs
    }
  }
}
