:root {
    .dx-theme-generic {
      --change-password-popup-height: 190px;
    }
  
    .dx-theme-material {
      --change-password-popup-height: 260px;
    }
  }
  
  @mixin separator() {
    .h-separator {
      height: 1px;
      width: auto;
      background: var(--border-color);
    }
  
    .separator {
      height: 24px;
      width: 1px;
      background: var(--border-color);
  
      .dx-toolbar-menu-section & {
        height: 1px;
        width: auto;
      }
    }
  }
  
  @mixin shadow() {
    box-shadow: 0 1px 4px 0 #00000026;
  }
  
  @mixin header {
    font-size: 22px;
    font-weight: 400;
    padding-right: 25px;
  }
  
  @mixin user-info {
    .user-info {
      margin-bottom: 20px;
      display: flex;
  
      .photo {
        width: 124px;
        height: 124px;
        margin-right: 20px;
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 2px 8px 0 #00000014;
        border: 1px solid #e0e0e0;
      }
    }
  
    .title {
      font-size: 12px;
      color: var(--texteditor-label-color);
  
      &:not(:first-child) {
        padding-top: 10px;
      }
    }
  
    .value {
      font-size: 16px;
      color: var(--accent-color);
  
      &.black {
        color: var(--base-text-color);
      }
  
      &.small {
        font-size: 12px;
      }
  
      .dx-icon {
        vertical-align: bottom;
        padding: 10px 12px 0 0;
      }
    }
  }
  
  @mixin message-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    color: var(--texteditor-label-color);
  }
  
  @mixin message-text {
    padding-top: 5px;
    line-height: 21px;
  }
  
  @mixin status-contact($status-color) {
    color: $status-color;
  
    &.dx-texteditor,
    &.dx-texteditor.dx-editor-filled {
      input[statuseditorinput][readonly] {
        color: $status-color;
        padding-left: 0;
      }
    }
  
    span {
      &::before {
        background-color: $status-color;
      }
    }
  }
  
  @mixin calendar-items-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  
  @mixin description-text($opacity) {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    opacity: $opacity;
  }
  
  @mixin h1-header-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
  
  @mixin h2-header-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  
  @mixin subtext($opacity: 1) {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    opacity: $opacity;
  }
  
  @mixin card-shadow() {
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.04);
  }
  
  @mixin toolbar-panel() {
    background-color: var(--base-bg);
    padding: 5px var(--content-padding);
    border-radius: 8px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.04);
  }